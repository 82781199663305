<template>
  <div class="jbxx">
    <el-form ref="form" :model="form" label-width="108px" :class="{ right2: !this.$store.state.rightCode.includes(2) }">
      <el-form-item class="huangzname" label="患者姓名">
        <el-input v-model="form.name" @input="fibbidface2" maxlength="16"></el-input>
      </el-form-item>

      <el-form-item label="性别" class="xingbtit">
        <el-radio-group v-model="form.resource">
          <el-radio label="男"></el-radio>
          <el-radio label="女"></el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item class="jiuzyy" label="就诊医院">
        <el-select v-model="form.yiy" :popper-append-to-body="false">
          <el-option
            v-for="(item, i) in yiyshuzu"
            :key="i"
            :label="item.country + item.province + item.city + item.area + item.address + item.hospitalName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button @click="xzyybut" style="font-size: 16px; width: 110px; color: #1175d2" type="text"
          >新增医院
        </el-button>
      </el-form-item>

      <el-form-item class="csrq" label="出生日期">
        <el-input v-model="input1" class="ipt1" @blur="yearxie"></el-input>
        <span>年</span>
        <el-input v-model="input2" class="ipt2" @input="monthxie"></el-input>
        <span>月</span>
        <el-input v-model="input3" class="ipt3" @input="datexie"></el-input>
        <span>日</span>
      </el-form-item>

      <el-form-item class="shdz" label="收货地址">
        <el-select v-model="form.diz" :popper-append-to-body="false">
          <el-option
            v-for="(item, i) in dizshuzu"
            :key="i"
            :label="item.country + item.province + item.city + item.area + item.address + ' - ' + item.deliveryName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button @click="xzdzbut" style="font-size: 16px; width: 110px; color: #1175d2" type="text"
          >新增地址
        </el-button>
      </el-form-item>

      <el-form-item class="shdz" label="口扫文件">
        <div class="wenjscbox">
          <el-upload
            class="upload-demo"
            action="{baseURL}"
            multiple
            accept=".zip,.rar,.7Z"
            ref="schuanwj"
            :on-change="handleSuccess"
            :auto-upload="false"
            :on-remove="remove"
            :limit="1"
            :file-list="fileList"
            :data="{
              caseId: this.$store.state.binglId,
              stageName: this.$store.state.stageName,
              stageCount: this.$store.state.stageCount,
              fileNumber: '12',
              add: this.scwjadd
            }"
            :headers="myHeaders"
          >
            <img class="scimg" :src="OK" @scimgDel="scimgDel" />
            <img class="close" :src="require('@/assets/close.png')" v-show="fileList.length > 0" />

            <div class="el-upload__text" v-show="fileList.length <= 0">点击上传扫文件</div>

            <div class="el-upload__tip1" slot="tip" v-if="fileList.length <= 0">
              文件限制：最大100M，支持.rar .zip .7z格式
            </div>
            <div class="el-upload__tip1" style="color: red" slot="tip" v-else>点击文件框即可删除上传文件</div>
          </el-upload>
          <div class="isUpload" :class="{ success: isUpload == '(上传成功)' }">{{ isUpload }}</div>
        </div>
      </el-form-item>
    </el-form>
    <newhospital @pushyiyshuj="pushyiyshuj" ref="tanchuangyiy"></newhospital>
    <newsite @pushdizhishuju="pushdizhishuju" ref="tanchuangdizhi"></newsite>
    <div class="elrowone">
      <el-button
        type="primary"
        :class="{ right2: !this.$store.state.rightCode.includes(2) }"
        style="background: #1175d2"
        @click="save"
        >保存</el-button
      >
      <el-button class="quxbut" type="primary" @click="cancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import { dateToStr } from '@/util/validate.js';
import newsite from '@/views/newtangc/newsite';
import newhospital from '@/views/newtangc/newhospital';
import OSS from 'ali-oss';
export default {
  components: {
    newsite,
    newhospital
  },
  data() {
    return {
      fileId: 0,
      isUpload: '',
      isOpen: true,
      caseNo: '',
      token: {},
      timer: 0,
      input1: '',
      input2: '',
      input3: '',
      form: {
        name: '',
        yiy: '',
        diz: '',
        resource: ''
      },
      paientinfomation: {},
      kkk: {},
      // 医院
      yiyshuzu: [],
      // 地址
      dizshuzu: [],
      //zip名字
      zipName: '',

      file: '',
      fileList: [],
      flag: true,
      OK: require('@/assets/3新建病例/上传文件.png'),
      NO: require('@/assets/3新建病例/PC-新建病例上传文件.png'),
      type: '',
      myHeaders: {
        Authorization: this.$cookie.get('token')
      },

      scwjadd: 'Y',
      loadingInstance: null,
      newImg: false,
      baseURL: null // 默认地址
    };
  },
  created() {
    this.baseURL = this.$http.defaults.baseURL + '/caseInfo/stepEight';
    var eightFormdata = new FormData();
    eightFormdata.append('caseId', this.$store.state.caseId);
    this.$http({
      url: '/caseInfo/getCaseInfoById',
      method: 'POST',
      data: eightFormdata
    })
      .then(({ data }) => {
        // console.log(data);
        let res = JSON.parse(data.data);

        this.caseNo = res.caseinfoId;
        this.token.accessKeySecret = res.accessKeySecret;
        this.token.accessKeyId = res.accessKeyId;
        this.token.securityToken = res.securityToken;
        this.token.ossAddress = res.ossAddress;
        this.token.bucketName = res.bucketName;

        /* if (data.code == 200) {
                  data = JSON.parse(data.data);
                  console.log(data);
                 
                } */
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted() {
    this.$store.commit('changeCopy', true);
    this.patientinfo();
    this.getfile();
  },
  methods: {
    saveScanLog(params) {
      this.$http({
        url: 'caseInfo/saveScanLog',
        method: 'POST',
        data: params
      })
        .then((data1) => {})
        .catch((res) => {});
    },
    // 年份限制
    yearxie(value) {
      let time = new Date();
      if (this.input1 && (this.input1 < 1900 || this.input1 > time.getFullYear())) {
        this.input1 = '';
        this.$message({
          message: `年份只能在${1900}年~${time.getFullYear()}年之间`,
          type: 'error'
        });
        return;
      }
    },
    // 月份限制
    monthxie(value) {
      this.input3 = '';

      this.input2 = value.replace(/[^\d]/g, '');
      if ((Number(value) <= 0 || Number(value) > 12) && value != '') {
        this.input2 = '';
        this.$message({
          message: '请输入正确的月份',
          type: 'error',
          duration: 2000,
          onClose: () => {
            this.visible = false;
            this.$emit('refreshDataList');
          }
        });
      }
    },
    // 日期限制
    datexie(value) {
      let isflg = true;
      if ([1, 3, 5, 7, 8, 10, 12].includes(Number(this.input2)) && this.input3 <= 31) {
        isflg = false;
      } else if ([4, 6, 9, 11].includes(Number(this.input2)) && this.input3 <= 30) {
        isflg = false;
      } else {
        if (this.input1 % 400 != 0 && this.input1 % 4 == 0) {
          if (this.input3 <= 29) {
            isflg = false;
          }
        } else {
          if (this.input3 <= 28) {
            isflg = false;
          }
        }
      }

      if (isNaN(this.input3) || this.input3s == '' || isflg) {
        this.input3 = '';
        this.$message({
          message: '请输入正确的日期',
          type: 'error',
          duration: 2000,
          onClose: () => {
            this.visible = false;
            this.$emit('refreshDataList');
          }
        });
      }
    },
    // 病人信息
    patientinfo() {
      this.$http({
        url: '/caseInfo/getStepTwo',
        method: 'get',
        params: {
          caseId: this.$store.state.caseId
        }
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.paientinfomation = JSON.parse(data.data);
            console.log(this.$store.state);
            this.form.name = this.paientinfomation.patientName;
            if (this.paientinfomation.sex === 1) {
              this.form.resource = '男';
            } else if (this.paientinfomation.sex === 2) {
              this.form.resource = '女';
            } else {
              this.form.resource = '';
            }
            this.input1 = dateToStr(new Date(this.paientinfomation.birthday)).substring(0, 4);
            this.input2 = dateToStr(new Date(this.paientinfomation.birthday)).substring(5, 7);
            this.input3 = dateToStr(new Date(this.paientinfomation.birthday)).substring(8, 10);
            // this.form.yiy=this.paientinfomation.hospitalId;
            // this.form.diz=this.paientinfomation.addressId;
            this.Hospitallist(this.paientinfomation.hospitalId);
            this.addreslist(this.paientinfomation.addressId);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取文件信息
    getfile() {
      this.$http({
        url: '/caseInfo/getStepEight',
        method: 'get',
        params: {
          caseId: this.$store.state.caseId
        }
      })
        .then(({ data }) => {
          data = JSON.parse(data.data);
          console.log(data);
          data.forEach((item) => {
            if (item.fileNumber == '12') {
              this.fileId = item.fileId;
              (this.fileList = [
                {
                  name: item.fileName,
                  url: item.path
                }
              ]),
                (this.flag = false);
              this.scwjadd = 'N';
            }
          });
        })
        .catch((err) => {});
    },
    // 上传病人信息接口
    uploadpatientinfo() {
      this.$http({
        url: '/caseInfo/stepTwo',
        method: 'post',
        data: this.kkk
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.loadingInstance.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              this.loadingInstance.close();
            });
            this.$store.commit('indexAdd', ['病例详情', '/particulars']);
            this.$router.push(this.$router.replace('/particulars'));
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit('refreshDataList');
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 医院列表
    Hospitallist(id) {
      this.$http({
        url: '/hospital/hospitalList',
        method: 'get'
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.yiyshuzu = JSON.parse(data.data);
            let that = this;
            this.yiyshuzu.forEach(function (item, idx) {
              if (item.id === id) {
                that.form.yiy = item.country + item.province + item.city + item.area + item.address + item.hospitalName;
              }
            });
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 地址列表
    addreslist(id) {
      this.$http({
        url: '/deliveryAddress/getAddressList',
        method: 'get'
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.dizshuzu = JSON.parse(data.data);
            let that = this;
            this.dizshuzu.forEach(function (item, idx) {
              if (item.id === id) {
                that.form.diz =
                  item.country + item.province + item.city + item.area + item.address + ' - ' + item.deliveryName;
              }
            });
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    xzyybut() {
      this.$refs.tanchuangyiy.dialogFormVisibleyiy = true;
    },
    xzdzbut() {
      this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
    },

    // 禁止输入表情
    fibbidface2(value) {
      var html = value,
        oldHtml = '';
      oldHtml = html;
      var reg =
        /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
      if (html.match(reg)) {
        html = html.replace(reg, '');
      }

      if (html != oldHtml) {
        this.form.name = html;
      }
      // if (this.form.name.length < 1) {
      //     this.$message({
      //         message: "请至少输入一位姓名",
      //         type: "error",
      //         duration: 2000,
      //         onClose: () => {
      //             this.visible = false;
      //             this.$emit("refreshDataList");
      //         }
      //     });
      // }
    },

    // emit触发医院列表事件
    pushyiyshuj() {
      this.$http({
        url: '/hospital/hospitalList',
        method: 'get'
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.yiyshuzu = JSON.parse(data.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // emit触发地址列表事件
    pushdizhishuju() {
      this.$http({
        url: '/deliveryAddress/getAddressList',
        method: 'get'
      })
        .then(({ data }) => {
          if (data.code == 200) {
            this.dizshuzu = JSON.parse(data.data);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 判断文件 是否
    ifType(file) {
      const fileExt = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
      const isJPG = fileExt == '.rar' || fileExt == '.zip' || fileExt == '.7z';
      const isLt2M = file.raw.size / 1024 / 1024 < 100;
      if (!isJPG) {
        this.$message.error('上传文件只支持 rar/zip/7z 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传文件最大100MB !');
        return false;
      }
      return true;
    },
    // 改变事件
    handleSuccess(file, fileList) {
      if (!this.ifType(file)) {
        this.fileList = [];
        return;
      }

      this.file = file;
      this.fileList = fileList; //需要上传的文件
      this.scwjadd = 'N'; //是否新建
      this.flag = false;
      this.upLoadFile();
    },
    // 删除文件
    remove() {
      if (this.isOpen) {
        this.isOpen = false;
        this.fileList = [];
        this.removeFile();
      }
    },
    // 取消按钮
    cancel() {
      this.$router.push('/particulars');
      this.$store.commit('indexRemove', 2);
    },
    checkYear() {},
    checkDay() {},
    // 保存按钮
    save() {
      let time = new Date();

      if (this.input1 && this.input2 && this.input3) {
        let now = new Date(this.input1 + '-' + this.input2 + '-' + this.input3);
        if (time < now) {
          this.input1 = '';
          this.$message({
            message: `出生日期不能大于当前时间`,
            type: 'error'
          });
          this.input1 = '';
          this.input2 = '';
          this.input3 = '';
          return;
        }
      } else {
        this.$message({
          message: `请填写出生日期`,
          type: 'error'
        });
        return;
      }
      this.time = new Date().getTime();
      this.loadingInstance = this.Loading.service({
        text: '正在上传'
      });
      this.kkk.caseId = this.$store.state.caseId;
      this.kkk.patientName = this.form.name;
      if (this.form.name.trim().length <= 0) {
        this.$message({ message: '患者名称不能为空', type: 'error', duration: 1500 });
        this.loadingInstance.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        });
        return;
      }

      if (this.form.diz.length <= 0) {
        this.$message({ message: '地址不能为空', type: 'error', duration: 1500 });
        this.loadingInstance.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        });
        return;
      }

      if (this.form.resource === '男') {
        this.kkk.sex = 1;
      } else if (this.form.resource === '女') {
        this.kkk.sex = 2;
      } else {
        this.kkk.sex = 0;
      }
      this.kkk.birthday = new Date(this.input1 + '/' + this.input2 + '/' + this.input3).getTime();
      if (typeof this.form.yiy === 'string') {
        this.kkk.hospitalId = this.paientinfomation.hospitalId;
      } else {
        this.kkk.hospitalId = this.form.yiy;
      }
      if (typeof this.form.diz === 'string') {
        this.kkk.addressId = this.paientinfomation.addressId;
      } else {
        this.kkk.addressId = this.form.diz;
      }
      this.uploadpatientinfo();
      /*  this.upLoadFile();
            if (!this.flag) return
            this.removeFile(); */
    },
    // 上传文件
    upLoadFile() {
      this.loadingInstance = this.Loading.service({
        text: '正在上传'
      });
      const data = {
        // 上传文件
        file: this.file.raw,
        // 病例 id
        caseId: this.$store.state.caseId,
        // 病例阶段
        stageName: this.$store.state.stageNameId,
        // 病例次数
        stageCount: this.$store.state.stageCountId,
        // 文件序号
        fileNumber: '12',
        //是否新增
        add: 'N'
      };
      /*    var eightFormdata = new FormData();
               for (var k in data) {
                   eightFormdata.append(k, data[k]);
               }
               this.$http({
                   url: "/caseInfo/stepEight",
                   method: "POST",
                   data: eightFormdata,
               }).then(({
                   data
               }) => {
                   this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                       this.loadingInstance.close();
   
                   });
                   this.$message({
                       message: "保存成功上传成功",
                       type: "success",
                       duration: 1500,
                       onClose: () => {
                           this.visible = false;
                           this.$emit("refreshDataList");
                       }
                   });
                   this.$router.push("/particulars");
                   this.$store.commit("indexRemove", 2);
               }).catch(err => {
                   this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                       this.loadingInstance.close();
   
                   });
                   this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 })
                   console.log(err)
               })  */
      console.log(this.token, 'jbbl');
      var client = new OSS({
        region: 'oss-' + this.token.ossAddress, // oss地址
        accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
        accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
        stsToken: this.token.securityToken,
        bucket: this.token.bucketName, // 仓库名字
        useFetch: true // 支持上传大于100KB的文件
        // secure:true // 返回的url为https
      });
      let stageName = this.$store.state.stageNameId;
      let stage = '';
      if (stageName == 1) {
        stage = 'FirstDesign';
      } else if (stageName == 2) {
        stage = 'MidTermRestart';
      } else if (stageName == 3) {
        stage = 'FinalAdjustment';
      } else if (stageName == 4) {
        stage = 'AfterProduction';
      }
      var fileName =
        this.caseNo +
        '/' +
        stage +
        '/' +
        this.$store.state.stageCountId +
        '/SCAN/' +
        localStorage.getItem('outerNo') +
        '_' +
        localStorage.getItem('patientName') +
        '_' +
        new Date().getTime() +
        data.file.name.substr(data.file.name.indexOf('.'));
      let uuid = new Date().getTime();
      this.saveScanLog({
        uuid,
        outerno: this.caseNo,
        file: fileName,
        stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
        type: 2
      });
      const options = {
        partSize: 1000 * 1024, //设置分片大小
        timeout: 1000 * 300 //设置超时时间
      };
      client
        .multipartUpload(fileName, data.file, options)
        .then((res) => {
          this.isUpload = '(上传成功)';
          this.saveScanLog({
            uuid,
            outerno: this.caseNo,
            file: fileName,
            stage: this.$store.state.stageName == 1 ? 1 : 2, //1新建 2重启
            type: 1
          });
          console.log(res);

          let params = {
            // 病例 id
            caseId: this.$store.state.caseId,
            // 病例阶段
            stageName: this.$store.state.stageNameId,
            // 病例次数
            stageCount: this.$store.state.stageCountId,
            // 文件序号
            fileNumber: '12',
            //是否新增
            add: 'N',
            ossfilePath: res.name,
            fileName: data.file.name
          };
          var eightFormdata = new FormData();
          for (var k in params) {
            eightFormdata.append(k, params[k]);
          }
          this.$http({
            url: '/caseInfo/stepEditScan',
            method: 'POST',
            data: eightFormdata
          })
            .then(({ data }) => {
              if (data.code == 200) {
                let res = JSON.parse(data.data);
                this.fileId = res.id;
              }

              this.loadingInstance.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                this.loadingInstance.close();
              });
              this.$message({
                message: '保存成功上传成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            })
            .catch((err) => {
              this.loadingInstance.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                this.loadingInstance.close();
              });
              this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 });
              console.log(err);
            });
        })
        .catch((err) => {
          this.loadingInstance.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close();
          });
          this.isUpload = '(上传失败)';

          this.$message({ message: '上传oss失败' + err, type: 'error', duration: 1500 });
          console.log(err);
        });
    },
    // 删除文件
    removeFile() {
      this.$http({
        url: '/caseInfo/delPhoto',
        method: 'POST',
        data: {
          caseId: this.$store.state.caseId,
          stageName: this.$store.state.stageNameId,
          stageCount: this.$store.state.stageCountId,
          fileNumber: '12',
          fileId: this.fileId
        }
      })
        .then(({ data }) => {
          this.$message.success('删除成功');
          this.isUpload = '';
          setTimeout(() => {
            this.isOpen = true;
          }, 800);
        })
        .catch((err) => {
          this.isUpload = '';

          this.$message.error('删除失败,请刷新后重新删除');
          setTimeout(() => {
            this.isOpen = true;
          }, 800);
          console.log(err);
        });
    },
    // 删除
    scimgDel() {}
  }
};
</script>
<style lang="less" scoped>
@media screen and (max-width: 1660px) {
  /deep/.el-form {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 1200px) {
  /deep/.shdz input,
  /deep/.el-textarea .el-textarea__inner {
    width: 600px !important;
  }

  /deep/.el-checkbox-button__inner {
    width: 80px !important;
  }
}
</style>
<style scoped lang="less">
.close {
  width: 40px;
  position: absolute;
  right: 190px;
  top: 60px;
  color: #333333;
  font-size: 40px;
}

/deep/.upload-demo {
  .el-upload-list {
    position: absolute;
    left: 50%;
    top: 180px;
    transform: translateX(-50%);
    width: 100%;

    .el-upload-list__item-status-label,
    .el-icon-close {
      top: -158px;
      right: 0;
      opacity: 0;
      display: block;
      background: red;
    }

    .el-upload-list__item-status-label {
      .el-icon-upload-success {
        width: 504px;
        height: 254px;
      }
    }

    .el-icon-close {
      width: 504px;
      height: 254px;
    }
  }
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.right2 {
  pointer-events: none;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.jbxx {
  min-height: 682px;
  font-size: 16px;
  background: #f9f9f9;
  padding-bottom: 50px;
}

// 上传zip文件
.wenjscbox {
  font-weight: 400;
  width: 500px;
  height: 250px;
  position: relative;
  border: 2px dashed #d9d9d9;
  border-radius: 6px;

  &:hover {
    border-color: #1175d2;
  }

  /deep/.upload-demo .el-upload-list {
    position: absolute;
    left: 50%;
    top: 145px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;

    /deep/.el-icon-close {
      top: -180px;
      right: -0px;
      opacity: 0;
      width: 504px;
      height: 254px;
    }

    .el-upload-list__item-status-label {
      top: -180px;
      right: 0x;
      opacity: 0;
      display: block;

      .el-icon-upload-success {
        width: 504px;
        height: 254px;
      }
    }
  }

  /deep/.el-upload-list {
    text-align: center;
  }
  /deep/.el-upload-list__item-name {
    margin: 0;
    font-size: 18px;
    white-space: normal;
    line-height: 20px;
  }
  /deep/.el-upload {
    position: relative;
    width: 500px;
    height: 250px;

    .scimg {
      position: absolute;
      left: 51%;
      top: 46%;
      transform: translate(-50%, -50%);
    }

    .el-upload__text {
      position: absolute;
      left: 50%;
      top: 70%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #f99400;
      font-size: 16px;
    }
  }

  .el-upload__tip1 {
    width: 500px;
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: #333333;
    margin-top: -14px;
  }
}

/deep/.el-button {
  padding: 0px;
  width: 140px;
  height: 36px;
  border: none;
  font-size: 16px;
}

// 按钮
.elrowone {
  // float: left;
  margin-left: 30px;
  margin-top: 130px;
  margin-bottom: 30px;

  .el-button {
    margin-right: 30px;
  }

  .quxbut {
    background: none !important;
    border: 1px solid #1175d2;
    color: black;
  }
}

/deep/.el-form-item {
  color: black;
  font-weight: 600;
  margin-bottom: 25px;

  .el-input input {
    border: 1px solid #1175d2;
  }
}

/deep/.el-form-item__label {
  text-align: center;
  font-size: 16px;
  padding-right: 0px;
  text-align: left;
  // margin-right:40px;
}

/deep/.el-form-item__content {
  // margin-left:40px !important;
}

/deep/.huangzname .el-form-item__content {
  width: 400px;
}

/deep/.huangzname {
  padding-top: 36px;
  box-sizing: border-box;
}

/deep/.jiuzyy .el-input--suffix .el-input__inner {
  width: 400px;
}

/deep/.shdz .el-input--suffix .el-input__inner {
  width: 800px;
}

/deep/.csrq .el-form-item__content .el-input {
  width: auto;
}

/deep/.csrq .ipt1 .el-input__inner {
  font-weight: 400 !important;
  width: 100px;
  margin-right: 10px;
  text-align: center;
}

/deep/.csrq .ipt2 .el-input__inner {
  font-weight: 400 !important;
  width: 80px;
  margin-right: 10px;
  margin-left: 30px;
  text-align: center;
}

/deep/.csrq .ipt3 .el-input__inner {
  font-weight: 400 !important;
  width: 80px;
  margin-right: 10px;
  margin-left: 30px;
  text-align: center;
}

/deep/.csrq span {
  font-weight: 400 !important;
}

/deep/.huodxs .el-textarea__inner {
  width: 800px;
  height: 150px;
  outline: none;
  resize: none;
  font-size: 16px;
  border: 1px solid #1175d2;
}

/deep/.el-form {
  padding-left: 80px;
}

/deep/.asflei .el-radio-button {
  // margin-right:34px;
}

/deep/.asflei .el-radio-button__inner {
  padding: 0px;
  line-height: 30px;
  width: 100px;
  height: 30px;
  margin-right: 25px;
  border: 1px solid #1175d2;
  border-radius: 4px;
  box-shadow: none;
}

/deep/.duoxuanfl .el-checkbox-button__inner {
  line-height: 30px;
  width: 100px;
  height: 30px;
  padding: 0px;
  margin-right: 25px;
  border: 1px solid #1175d2;
  border-radius: 4px;
  box-shadow: none;
}

/deep/.xingbtit .el-form-item__label {
  letter-spacing: 30px;
  padding: 0px;
  text-align: left;
}

// 性别
/deep/.el-radio__inner {
  border: 1px solid #1175d2 !important;
  width: 20px;
  height: 20px;
}

// 新增
/deep/.el-select .el-input .el-select__caret {
  color: #1175d2;
  font-size: 20px;
  font-weight: 1000;
  // el-icon-caret-bottom
}

/deep/.el-icon-arrow-up:before {
  content: '\e6e1';
}

@media screen and (max-width: 1660px) {
  /deep/.asflei .el-radio-button__inner {
    margin-right: 10px;
  }

  /deep/.duoxuanfl .el-checkbox-button__inner {
    margin-right: 10px;
  }
}

/deep/ .el-upload-list__item-name {
  margin-right: 0;
}
.isUpload {
  position: absolute;
  top: 218px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: red;
}
.success {
  color: green;
}

/deep/.el-select-dropdown__list {
  padding: 6px 0 15px 0 !important;
  font-weight: 400 !important;
}
</style>