import Vue from 'vue';
// 兼容不同的客户
// http://case.magicalign.com 默认地址
export default class Compatible {

  registeredName = "成为正丽科技注册医生";
  title = "正小丽"
  typeLog = true;
  isvvsmile = false;
  isqiqimei = false;
  url = ''
  isJge = false;
  isAMKQ = false;
  website = null;
  isJYYX = false
  ismysj = false
  isXhg = false
  caseBrands = true
  loginImage = require('@/assets/1顶部导航/logo.png'); //默认图片;
  loginImage3D = require('@/assets/1顶部导航/logo3d.png'); //默认图片;

  companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像2.png');


  copyRight = '版权所有 ©2025 正丽科技 津ICP备16006009号 广告审批文号:津械广审(文)第210412-00386号';
  webCore = {
    // 地址
    pc: ' http://case.magicalign.com/web',
    phone: ' http://case.magicalign.com/wap/',
    webTitle: '轻松笑',
    webLogo: '',
  };
  allFuction = {
    //功能修改
    register: true, //登录页注册按钮
    help: true //帮助中心
  };
  init(environment) {
    this.website = window.location.href;
    this.logo(environment);
    this.Functionality();
    return this;
  }
  logo(environment) {
    if (environment) {

      //生产环境
      this.webCore.pc = 'http://case.magicalign.com/web';
      this.webCore.phone = 'http://case.magicalign.com/wap/';
      // this.webCore.phone = 'http://180.166.104.62:5000/wap/'; //'https://magicalign.top/wap/';


    } else {


      //开发环境
      this.webCore.pc = 'http://203.156.250.44:5000/'; //'https://magicalign.top/';
      this.webCore.phone = 'http://203.156.250.44:5000/wap/'; //'https://magicalign.top/wap/';
    }

    if (this.website.indexOf('http://easysmile.magicalign.com') != -1) { //轻松笑
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png');
      this.title = '轻松笑'
      this.loginImage = require('@/assets/easysmilelogo.png');
      // 切换跳转地址
      this.webCore.pc = 'http://easysmile.magicalign.com';
      this.webCore.phone = 'http://easysmile.magicalign.com/wap/';

      // console.log(this.webCore.pc)

      // } else if (this.website.indexOf('http://localhost:8080') != -1) { // 绮齐美、
    } else if (this.website.indexOf('plusbeauty.magicalign.com') != -1 || this.url == 'qiqimei') {
      this.title = '绮齐美'
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png');

      this.loginImage3D = require('@/assets/qiqimei_logo_details.png')
      this.loginImage = require('@/assets/qiqimei_logo_details.png');
      this.registeredName = "成为PB绮齐美注册医生"
      this.isqiqimei = true;
      this.typeLog = false;

      this.copyRight = '版权所有 ©2025 正丽科技 津ICP备16006009号 广告审批文号:津械广审(文)第210412-00386号';
    } else if (this.website.indexOf('vvsmile.magicalign.com') != -1 || this.url == 'vvsmile') {// vvSmile

      this.title = 'vvSmile'
      this.isvvsmile = true;
      this.loginImage3D = require('@/assets/vvSmile.png');
      this.companyLogo = require('@/assets/vvsmileICON.png');
      this.registeredName = "成为注册医生"
      this.typeLog = false;
      this.loginImage = require('@/assets/vvSmile.png');
      this.copyRight = '版权所有 ©2025 正丽科技 津ICP备16006009号 广告审批文号:津械广审(文)第210412-00386号 '
      // 切换跳转地址
      this.webCore.pc = 'http://vvsmile.magicalign.com';
      this.webCore.phone = 'http://vvsmile.magicalign.com/wap/';
      if (this.url == 'vvsmile') {
        this.webCore.phone = 'http://203.156.250.44:5000/wap/';
      }
      document.querySelectorAll('.faviconICON')[0].href = './bitbug_favicon_LM.png'
      document.getElementsByTagName('title')[0].innerHTML = 'vvsmile病例管理系统'
    } else if (this.website.indexOf('jiegeer.magicalign.com') != -1 || this.isJge) {
      this.title = '杰格尔'
      this.loginImage = require('@/assets/jge_logo.png');
      this.isJge = true;
      this.loginImage3D = require('@/assets/jge_logo.png');
      this.typeLog = false
      this.registeredName = ''
      // 切换跳转地址
      this.webCore.pc = 'http://jiegeer.magicalign.com';
      this.webCore.phone = 'http://jiegeer.magicalign.com/wap/';
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png');

      // console.log(this.webCore.pc)
    } else if (this.website.indexOf('smiletrack.magicalign.com') != -1 || this.isAMKQ) {
      this.typeLog = false
      this.title = '安美口腔'
      this.isAMKQ = true
      this.copyRight = ""
      this.loginImage3D = require('@/assets/amkq_logo_details.png')
      this.loginImage = require('@/assets/amkq_logo_details.png');
      this.registeredName = "Smile Track注册医生"
      this.webCore.pc = 'http://smiletrack.magicalign.com/web';
      this.webCore.phone = 'http://smiletrack.magicalign.com/wap/';
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png');

    } else if (this.website.indexOf('http://cherrysmile.magicalign.com/') != -1 || this.isJYYX) {
      this.typeLog = false
      this.title = '萌芽世界'
      this.isJYYX = true
      this.copyRight = ""
      this.loginImage3D = require('@/assets/jyyx.png')
      this.loginImage = require('@/assets/jyyx.png');
      this.registeredName = "萌芽世界注册医生"
      this.webCore.pc = 'http://cherrysmile.magicalign.com/web';
      this.webCore.phone = 'http://cherrysmile.magicalign.com//wap/';
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png'); //头像
    } else if (this.website.indexOf('http://moresmile.magicalign.com/') != -1 || this.ismysj) {
      this.typeLog = false
      this.title = '在线客服'
      this.ismysj = true
      this.copyRight = ""
      this.loginImage3D = require('@/assets/mysj.png')
      this.loginImage = require('@/assets/mysj.png');
      this.registeredName = "注册医生账号"
      this.webCore.pc = 'http://moresmile.magicalign.com/web';
      this.webCore.phone = 'http://moresmile.magicalign.com//wap/';
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png'); //头像
    }else if (this.website.indexOf('http://yae920.magicalign.com') != -1 || this.isXhg) {
      //else if (this.website.indexOf('http://www.magicalign.online') != -1 || this.isXhg) {
      // http://www.magicalign.online   http://172.168.0.140:8080
      this.allFuction.help = false
      this.copyRight = '版权所有 ©2025 正丽科技 津ICP备16006009号 广告审批文号:津械广审(文)第210412-00386号'
      this.registeredName = "成为牙e在线注册医生"
      this.title = '在线客服'
      this.isXhg = true;
      this.caseBrands = true
      // this.feedback = '牙e在线'
      this.loginImage = require('@/assets/xinhuag_logo.png');
      this.loginImage3D = require('@/assets/xinhuag_logo.png');
      this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像2.png'); //头像
      this.typeLog = false;
      this.webCore.pc ='http://yae920.magicalign.com:9999/web'; 
      this.webCore.phone = 'http://yae920.magicalign.com/wap/';

    }
    // else if (this.website.indexOf('http://172.168.0.140:8080') != -1 || this.isJge) {
    //   this.title = '杰格尔'
    //   this.loginImage = require('@/assets/jge_logo.png');
    //   this.isJge = true;
    //   this.loginImage3D = require('@/assets/jge_logo.png');
    //   this.typeLog = false
    //   this.registeredName = ''
    //   // 切换跳转地址
    //   this.webCore.pc = 'http://jiegeer.magicalign.com';
    //   this.webCore.phone = 'http://jiegeer.magicalign.com/wap/';
    //   this.companyLogo = require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像1.png');

    //   // console.log(this.webCore.pc)
    // }
  }

  Functionality() {
    if (this.website.indexOf('http://easysmile.magicalign.com') != -1) {
      this.allFuction.register = false;
      this.allFuction.help = false
    }
  }
}